.mainDescription{
   display: none;
}
.coreValues{
    margin-top: 3.3vw;
}
.mainTour{
    margin: 0;
    padding: 0;
    /*margin-left: 10vw;*/
    /*margin-bottom: 1.83vw;*/
}
.firstStep{
    margin: 0;
    padding: 0;
}
.whitePaper{
    margin-left: 8vw;
    margin-top: 6vw;
}
.slideShow{
    margin-top: 1.83vw;
    margin-left: 8.15vw;
    margin-bottom: 1.83vw;
    max-width: 82.33vw;
}
.mainPanel{
    caret-color: transparent;
    /*background-color: #E3E1E1;*/
    /*background: #787878;*/
}

.body{
    max-width: 1200px;
    margin: auto;
}
@media only screen and (max-width: 800px) {
    .mainDescription{
        display:inherit;
        /*margin-top: 64px;*/
    }
}
