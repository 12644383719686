
.footerMain{
    margin-top: 84px;
}
.footerContent{
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    margin: auto 89px;
}
.titleMain{
    display: flex;
    flex-flow: wrap;
}

.footerLogo{
    width: 46px;
    margin-right: 12px;
}

.block{
    display:block;
    /* margin-right: 25px; */
}

.title{

    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    /* identical to box height, or 112% */

    text-align: left;

    /* Colors / Heading */

    color: #1E0E62;
    margin: 11px 0 0 0;
}
.subTitleText{
    width: 235px;

    /* font-style: normal; */
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.38);
    margin-top: 35px;
}
.titleText{
    max-width: 220px;
    height: 46px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.38);
    margin-top: 24px;
}
.firstMenu{
    /* margin-right: 102px; */
}

.button{

    text-decoration: none !important;
    /* font-style: normal !important; */
    font-weight: 400!important;
    font-size: 16px !important;
    color: #1E0E62 !important;

}

.secondMenu{
    /* margin-left: 50px; */
}
.footerButton{
    margin-bottom: 10px;
}
.subscribeMain{
}
.subscribeTitle{


    /* font-style: normal; */
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    /* identical to box height, or 160% */


    /* Colors / Heading */

    color: #1E0E62;
    padding-top: 10px;

}
.subscribeInputs{
    display: flex;
    flex-flow: wrap;
    padding-top: 27px;

}
.textField{
    border-radius: 40px;
    border-color: red;
    width: 232px;
    height: 40px;

}
.subscribeButton{
    margin-left: 10px;
    margin-top: 5px;
}
.footerText{

    width: 100%;
    /* font-style: normal; */
    font-weight: 300;
    font-size: 14px;
    line-height: 27px;
    /* identical to box height, or 162% */

    text-align: center;

    /* Colors / Text */

    color: rgba(21, 20, 57, 0.4);

    mix-blend-mode: normal;

    margin-top: 23px;
    margin-bottom: 26px;
}
.divider{
    width: 100%;
    margin-top: 228px;
}
.firstFooterMenuHeader{

    /* font-style: normal !important; */
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 30px !important;
    /* identical to box height, or 188% */


    /* Colors / Heading */

    color: #1E0E62;

    padding-top: 10px;
}
.secondFooterMenuHeader{

    /* font-style: normal; */
     font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    /* identical to box height, or 188% */


    /* Colors / Heading */

    color: #1E0E62;

    padding-top: 10px;
}

.linkedIn {
    background: url("./linkedin.svg") no-repeat;
    background-size: cover;
}

.linkedIn:hover {
    background: url("./linkedin-hover.svg") no-repeat;
    background-size: cover;
}

.twitter {
    background: url("./twitter.svg") no-repeat;
    background-size: cover;
}

.twitter:hover {
    background: url("./twitter-hover.svg") no-repeat;
    background-size: cover;
}

.telegram {
    background: url("./telegram.svg") no-repeat;
    background-size: cover;
}

.telegram:hover {
    background: url("./telegram-hover.svg") no-repeat;
    background-size: cover;
}

.facebook {
    background: url("./facebook.svg") no-repeat;
    background-size: cover;
}

.facebook:hover {
    background: url("./facebook-hover.svg") no-repeat;
    background-size: cover;
}

.medium {
    background: url("./medium.svg") no-repeat;
    background-size: cover;
}

.medium:hover {
    background: url("./medium-hover.svg") no-repeat;
    background-size: cover;
}

.contactLogo {
    width: 35px;
    height: 35px;
    vertical-align: bottom;
    margin-right: 12px;

}

.iconLink{
    margin-right: 12px;
}

.footerContactInformation {
    display: flex;
    flex-flow: wrap;
    margin: 20px auto 20px auto;
}

.footerContactInformationMobile{
    display: none;
}

/* Create two equal columns that floats next to each other */
.column {
    float: left;
    width: 500px;
    padding: 10px;
    height: 300px; /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.flexStyle{
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    width: 200px;

    margin-right: 60px;
}

.roundedFooterButton{
    width: 131px
}

@media (max-width: 1200px) {

    .footerContent {
        margin: auto 7.5%;
    }
    .roundedFooterButton{
        width: 100px
    }
}
@media (max-width: 360px) {
    .block{
        margin-right: 0;
    }
    .firstMenu{
        margin-right: 50px;
    }
    .flexStyle{
        margin-right: 0;
    }

}

/*@media (max-width: 1200px) {*/
/*    .divider{*/
/*        width: 100%;*/
/*        margin-top: 228px;*/
/*    }*/
/*    .footerText{*/
/*        width: 100%;*/
/*    }*/
/*    .footerContent{*/
/*        display: block;*/
/*        !*width: calc(100% - 50px);*!*/
/*        margin-left: 8.8%;*/
/*    }*/
/*    .block{*/
/*        margin: auto;*/
/*        width: 100%;*/
/*    }*/

/*    .titleText{*/
/*        width: 100%;*/
/*    }*/
/*    .footerContactInformation{*/
/*        display: none;*/
/*    }*/
/*    .footerContactInformationMobile{*/
/*        display: flex;*/
/*        margin-top: 20px;*/
/*    }*/

/*    .firstMenu{*/
/*        margin-left: 0;*/
/*    }*/

/*    .secondMenu{*/
/*        margin-left: 72px;*/
/*    }*/

/*    .flexStyle{*/
/*        display: flex;*/
/*        margin-left: 18px;*/
/*    }*/

/*    .subscribeMain{*/
/*        margin-left: 0;*/
/*    }*/

/*    .subscribeInputs{*/
/*        width: 95%;*/
/*    }*/

/*    .textField{*/
/*        width: 172px;*/
/*    }*/

/*    .divider{*/
/*        margin-top: 44px;*/
/*    }*/

/*    .footerTextField{*/
/*        width: 172px*/
/*    }*/

/*    .roundedFooterButton{*/
/*        width: 97px*/
/*    }*/
/*}*/