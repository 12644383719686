
.sMainTitle{

    /* font-style: normal; */
     font-weight: 550;

    /*font-size: 7.81vw;*/
    /*line-height: 10.93vw;*/

    font-size: 25px;
    line-height: 35px;

    text-align: center;
    letter-spacing: -0.7px;

    color: #1E0E62;

    margin: 100px auto;
    max-width: 550px;
    width: calc(100% - 11%);

    /*margin-top: 23.75vw;*/
    /*margin-bottom: 26.21vw;*/
}
.sMainDiv{
    width: 88.75%;
    margin-right: 5.625%;
    margin-left: 5.625%;
    text-align: center;
}
.sImage{
    max-width: 463px;
    margin: auto;
    width: 100%;
}
.sBTitle{
    width: calc(100% - 11%);
    margin: 60px auto 20px auto;


    /* font-style: normal; */
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    /* or 133% */

    text-align: center;
    letter-spacing: -0.4px;

    /* Colors / Heading */

    color: #1E0E62;
}
.sBText{
    margin: auto auto 90px auto;


    /* font-style: normal; */
    /* font-weight: 400; */
    font-size: 18px;
    line-height: 27px;
    /* or 150% */

    text-align: justify;

    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
    max-width: 639px;
}
.sMakeMonetTitle{
    width: calc(100% - 11%);
    margin: 60px auto 20px auto;


    /* font-style: normal; */
     font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    /* or 133% */

    text-align: center;
    letter-spacing: -0.4px;

    /* Colors / Heading */

    color: #1E0E62;
}
.sMakeMonetText{
    margin: auto auto 90px auto;


    /* font-style: normal; */
    /* font-weight: 400; */
    font-size: 18px;
    line-height: 27px;
    /* or 150% */

    text-align: justify;

    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
    max-width: 639px;
}
.sContactToOriginalTitle{
    width: calc(100% - 11%);
    margin: 60px auto 20px auto;


    /* font-style: normal; */
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    /* or 133% */

    text-align: center;
    letter-spacing: -0.4px;

    /* Colors / Heading */

    color: #1E0E62;
}
.sContactToOriginalText{
    margin: auto auto 90px auto;


    /* font-style: normal; */
    /* font-weight: 400; */
    font-size: 18px;
    line-height: 27px;
    /* or 150% */

    text-align: justify;

    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
    max-width: 639px;
}
.sReduceCostTitle{
    width: calc(100% - 11%);
    margin: 60px auto 20px auto;


    /* font-style: normal; */
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    /* or 133% */

    text-align: center;
    letter-spacing: -0.4px;

    /* Colors / Heading */

    color: #1E0E62;
}
.sReduceCostText{
    margin: auto auto 90px auto;


    /* font-style: normal; */
    /* font-weight: 400; */
    font-size: 18px;
    line-height: 27px;
    /* or 150% */

    text-align: justify;

    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
    max-width: 639px;
}
