
.sMainTitle{
    font-style: normal;
    font-weight: 550;
    font-size: 25px;
    line-height: 35px;

    text-align: center;
    letter-spacing: -0.7px;

    color: #1E0E62;

    margin: 55px auto 100px auto;
    max-width: 550px;
    width: 97%;


}
.sMainDiv{
    width: 88.75%;
    margin-right: 5.625%;
    margin-left: 5.625%;
    text-align: center;
}
.sImage{
    max-width: 240px;
    margin: auto;
    width: 90%;
}
.sBTitle{
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 60px;
    /* or 273% */

    text-align: center;
    letter-spacing: -0.4px;

    color: rgba(30, 14, 98, 0.87);

    width: calc(100% - 11%);
    margin: 40px auto 20px auto;

}
.sBText{
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;

    text-align: justify;

    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
    max-width: 640px;
    margin: auto;

    /*max-width: 639px;*/
}
.sMakeMonetTitle{
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 60px;
    /* or 273% */

    text-align: center;
    letter-spacing: -0.4px;

    color: rgba(30, 14, 98, 0.87);

    width: calc(100% - 11%);
    margin: 40px auto 20px auto;
}

.sMakeMonetText{
    margin: auto auto 0 auto;

    font-size: 16px;
    line-height: 27px;

    text-align: justify;

    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
    max-width: 640px;

}
.sContactToOriginalTitle{
    width: calc(100% - 11%);
    margin: 60px auto 20px auto;

    /* font-style: normal; */
    /* font-weight: 700; */
    font-size: 30px;
    line-height: 40px;
    /* or 133% */

    text-align: center;
    letter-spacing: -0.4px;

    /* Colors / Heading */

    color: #1E0E62;
}
.sContactToOriginalText{
    margin: auto auto 90px auto;


    /* font-style: normal; */
    /* font-weight: 400; */
    font-size: 18px;
    line-height: 27px;
    /* or 150% */

    text-align: justify;

    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
    /*max-width: 639px;*/
}
.sReduceCostTitle{
    width: calc(100% - 11%);
    margin: 60px auto 20px auto;


    /* font-style: normal; */
    /* font-weight: 700; */
    font-size: 30px;
    line-height: 40px;
    /* or 133% */

    text-align: center;
    letter-spacing: -0.4px;

    /* Colors / Heading */

    color: #1E0E62;
}
.sReduceCostText{
    margin: auto auto 90px auto;


    /* font-style: normal; */
    /* font-weight: 400; */
    font-size: 18px;
    line-height: 27px;
    /* or 150% */

    text-align: justify;

    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
    /*max-width: 639px;*/
}

.revenueDiv {
    position: relative;
    height: 900px;
    margin: auto;
    background: linear-gradient(84.41deg, #009EE2 6.54%, rgba(0, 158, 226, 0) 132.36%);
}

.revenueTextsDiv {
    position: absolute;
    width: 100%;
}

.revenueFirstTitle {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    letter-spacing: 3px;

    color: #000000;

    mix-blend-mode: normal;
    margin: 69px auto auto auto;
    text-align: center;

}

.revenueSecondTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;

    letter-spacing: -2px;
    color: #000000;
    margin: 39px auto auto auto;
    max-width: 256px;
    text-align: center;
    mix-blend-mode: normal;

}
.revenueText {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;

    color: #000000;
    mix-blend-mode: normal;
    margin: 55px auto auto auto;
    text-align: justify;
    max-width: 450px;
    padding-left: 30px;
    padding-right: 30px;;
}

.revenueImg {
    position: absolute;
    height: 388px;
    width: 100%;
    margin-top: 436px;
    text-align: center;
}

.sText2{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    color: rgba(0, 0, 0, 0.6);
    max-width: 640px;
    margin: 51px auto 90px auto;
}
.sText2_2{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    color: rgba(0, 0, 0, 0.6);
    margin: 51px auto 90px auto;
    max-width: 640px;
}