.mainDiv {
    width: 1030px;
    text-align: center;
    display: flex;
    margin: auto auto 150px;
}

.hero {
    width: 1030px;
    height: 453px;
    background-color: #D9DCDA;
    color: #4A763B;
    border-radius: 18px;
    margin: 100px auto 50px auto;
}

.heroText {
    position: absolute;
    width: 320px;
    text-align: left;
    font-size: 48px;
    font-weight: bold;
    line-height: 52px;
    margin: 172px 0 0 340px;
    letter-spacing: -1.5px;
}

.heroSvg {
    position: absolute;
    margin-top: 125px;
    margin-left: 100px;
}

.contentDiv {
    position: relative;
    max-width: 680px;
    padding-left: 50px;
    margin: auto;
}

.contentTextDiv {
    position: relative;
    max-width: 660px;
    margin: auto;
}

.menuDiv {
    background-color: #EFEFEF;
    width: 312px;

    border-radius: 28px;
    height: fit-content;
}

.menuTextDiv {
    max-width: 250px;

    margin: 30px auto auto;
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #666666;
}

.update {
    text-align: left;
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    color: #666666;
    margin-bottom: 10px;
}

.title {
    padding-top: 100px;
    text-align: left;
    font-size: 22px;
    font-weight: bold;
    line-height: 27px;
    color: #666666;
    margin-bottom: 10px;
    margin-top: -80px;
}

.text {
    text-align: left;
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    color: #666666;
}

a:link {
    color: #666666;
    background-color: transparent;
    text-decoration: none;
}

a:visited {
    color: #666666;
    background-color: transparent;
    text-decoration: none;
}

a:hover {
    color: #4A763B;
    background-color: transparent;
    text-decoration: none;
}

@media (max-width: 1200px) {
    .mainDiv {
        width: 90%;
        text-align: center;
        display: block;
        margin: auto auto 10%;
    }

    .hero {
        width: 90%;
        height: 33vw;


        border-radius: 18px;
        margin: 100px auto 50px auto;
    }

    .heroText {
        position: absolute;
        width: 25%;
        text-align: left;
        font-size: 4vw;
        font-weight: bold;
        line-height: 110%;
        margin: 13% 0 0 27%;
        letter-spacing: -1.5px;
    }

    .heroSvg {
        position: absolute;
        width: 14%;
        margin-top: 9.6%;
        margin-left: 10%;
    }

    .contentDiv {
        position: relative;
        max-width: 90%;
        padding-left: 0;
        margin: auto;
    }

    .contentTextDiv {
        position: relative;
        margin: auto;
    }

    .menuDiv {
        padding-top: 20px;

        width: 100%;

        border-radius: 28px;

        margin-bottom: 50px;
        padding-bottom: 20px;
    }

    .menuTextDiv {
        max-width: 90%;
        margin: 30px auto auto;
        text-align: left;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;

    }

    .update {
        text-align: left;
        font-size: 18px;
        font-weight: 300;
        line-height: 27px;

        margin-bottom: 10px;
    }

    .title {
        padding-top: 100px;
        text-align: left;
        font-size: 22px;
        font-weight: bold;
        line-height: 27px;

        margin-bottom: 10px;
        margin-top: -80px;
    }

    .text {
        text-align: left;
        font-size: 18px;
        font-weight: 300;
        line-height: 27px;

    }
}