
.mainPanel {
    position: absolute;
    top: 54px;
    width: 1200px;
    background-color: #4A763B;
    opacity: 98%;
    margin: 0 auto;
    left: 0;
    right: 0;

}
.mainContent{
    opacity: 98%;
    display: flex;
    justify-content: center;
}
.contentPanel {
    display: flex;
    justify-content: center;
    height: 400px;
    opacity: 98%;
    width: 1200px;
    color: #E8E8E8;
}

.contentPanelMobile {
    display: none;
}

@media only screen and (max-width: 1200px) {
    .mainPanel {
        position: absolute;
        /* margin: 0 0 auto auto; */
        top: 54px;
        right: 2%;
        left: 2%;
        width: 96%;
        background-color: #4A763B;
        opacity: 98%;
        /* margin: 0 0; */
        /* left: 0; */

    }

    .contentPanel{
        display: none;
    }

    .contentPanelMobile {
        display: flex;
        justify-content: left;
        height: 360px;
        opacity: 98%;
        width: 100%;
        color: #E8E8E8;
    }

    .mainContent{
        justify-content: left;
        max-height: calc(100vh - 56px);
        overflow: auto;
    }
}