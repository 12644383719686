@font-face {
    font-family: 'Sequel Sans';
    src: local('Sequel Sans'), url('./sans-sequel.otf') format("OpenType");;
    font-display: swap;
}

@font-face {
    font-family: 'ITC Avant Garde Std XLt';
    src: local('ITC Avant Garde Std XLt'), url('./itc.otf') format("OpenType");
    font-display: auto;
}


@font-face {
    font-family: 'HelveticaNeueCyr';
    src: local('HelveticaNeueCyr'), url('./HelveticaNeueCyr.otf') format("OpenType");
    font-display: auto;
    font-weight: 600;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: local('HelveticaNeueCyr-Light'), url('./HelveticaNeueCyr-Light.otf') format("OpenType");
    font-display: auto;
    font-weight: 300;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: local('HelveticaNeueCyr-Roman'), url('./HelveticaNeueCyr-Roman.otf') format("OpenType");
    font-display: auto;
    font-weight: 400;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: local('HelveticaNeueCyr-Medium'), url('./HelveticaNeueCyr-Medium.otf') format("OpenType");
    font-display: auto;
    font-weight: 500;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: local('HelveticaNeueCyr-Bold'), url('./HelveticaNeueCyr-Bold.otf') format("OpenType");
    font-display: auto;
    font-weight: 700;
}

