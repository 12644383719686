

.storiesDiv{
    width: 1200px;
    margin: auto;
}

.title{
    font-size: 40px;
    line-height: 52px;
    font-weight: 500;
    margin: 54px auto auto;
    text-align: center;
    max-width: 787px;
    color: #1E0E62;
    letter-spacing: -0.4px;
}

.secondTitle{
    font-size: 24px;
    margin: 39px auto auto;
    text-align: center;
    max-width: 625px;
    color: rgba(21, 20, 57, 0.4);
}

.story{
    margin-top: 140px;
    display: flex;
    width: 100%;
}

.stories{
    font-size: 24px;
    margin: 136px 0 160px 87px;
    text-align: center;
    width: 1035px;
}

.leftContent{
    display: flow-root;
    margin: 0 69px 0 0;
    width: 453px;
    color: rgba(0, 0, 0, 0.6);
}

.rightContent1{
    display: flow-root;
    margin: 0 0 0 113px;
    width: 453px;
    color: rgba(0, 0, 0, 0.6);
}

.rightContent2{
    display: flow-root;
    margin: 0 0 0 100px;
    width: 453px;
    color: rgba(0, 0, 0, 0.6);
}

.storiesTitle{

    display: flex;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 50px;
    text-align: left;
    max-width: 220px;
    color: #1E0E62;
    letter-spacing: -0.4px;
}

.fistStoriesTitle{

    display: flex;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 50px;
    text-align: left;
    max-width: 270px;
    color: #1E0E62;
    letter-spacing: -0.4px;
}

/* .fistStoriesTitle{
    display: flex;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 60px;
    text-align: left;
    max-width: 270px;
    color: #1E0E62;
} */

.learnMoreBtn{
    cursor: pointer;
    margin-top: 40px;
    float: left;
}

.storiesText{

    display: flex;
    font-size: 18px;
    line-height: 27px;
    margin: auto;
    text-align: justify;
    color: #00000099;
}

.icon1{
    width: 316.7px;
    height: 316.57px;
    margin-left: 100px;
    margin-top: 35px;
}
.icon2{
    width: 328px;
    height: 277px;
    margin-right: 40px;
    margin-left: 100px;
    margin-top: 75px;
}

.storiesSubText{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 63px;
    text-align: left;
    color: #00000099;
}

.revenueDiv {
    position: relative;
    width: 1000px;
    height: 600px;
    margin: auto auto 113px;
    background: linear-gradient(99.84deg, #009EE2 3.94%, rgba(0, 158, 226, 0) 103.73%);
    border-radius: 15px;
}

.revenueFirstTitle {
    position: absolute;
    left: 85px;
    top: 194px;

    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    letter-spacing: 3px;

    color: #FFFFFF;

    mix-blend-mode: normal;
}

.revenueSecondTitle {
    position: absolute;
    width: 279.72px;
    height: 90px;
    left: 85px;
    top: 232px;

    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    /* or 135% */

    letter-spacing: -2px;

    color: #FFFFFF;
}
.revenueText {
    position: absolute;
    left: 85px;
    top: 364px;

    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    max-width: 386px;


    color: #FFFFFF;

    mix-blend-mode: normal;
}

.revenueImg {
    position: absolute;
    width: 321px;
    height: 508px;
    left: 555px;
    top: 46px;
}

/*.chart{*/
/*    width: calc(100%  - 200px);*/
/*    max-width: 1200px;*/
/*    margin: auto auto;*/
/*}*/

@media (max-width: 1200px) {
    .storiesDiv{
        width: 88.75%;
        margin-right: 5.625%;
        margin-left: 5.625%;
    }
    .title{

        /* font-style: normal; */
        /* font-weight: 550; */
        font-size: 25px;
        line-height: 35px;
        /* or 140% */

        text-align: center;
        letter-spacing: -0.7px;

        color: #1E0E62;
    }
}
