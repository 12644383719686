.mainPanel{
    display: flex;
    width:970px;
}
.titlePanel{
    margin-right: 89px;
}
.link{
    cursor: pointer;
}
.titleFirstRow{
    display: flex;
}
.titleImage{

    width: 93px;
    height: 107px;
    margin-right: 24px;
    margin-bottom: 21px;
    margin-top: 105px;
}
.ideaBoosterImage{
    width: 20px;
    height: 25px;
    margin-right: 16px;
    margin-top: 81px;
}
.primaryMarketImage{
    margin-right: 16px;
    width: 20px;
    height: 25px;
    margin-top: 44px;
}
.firstRowTopText{
    margin-top: 81px;
}
.secondRowTopText{
    margin-top: 44px;
}
.firstColumnBellowText{
    margin-left: 36px;

    width: 222px;

    /* font-style: normal; */
    /* font-weight: 400; */
    font-size: 12px;
    line-height: 17px;
    /* or 142% */


    color: #FFFFFF;

    mix-blend-mode: normal;}

.menuItemTopText{
    margin-bottom: 13px;


    /* font-style: normal; */
    /* font-weight: 700; */
    font-size: 18px;
    line-height: 27px;
    /* or 150% */

    text-align: justify;

    color: #E8E8E8;

    mix-blend-mode: normal;
}

.menuItemTopText:hover {
    color: #F8FEB9;;
}

.titleTopText{
    margin-top: 158px;

    /* font-style: normal; */
    /* font-weight: 700; */
    font-size: 24px;
    line-height: 27px;
    /* or 150% */

    text-align: justify;

    color: #E8E8E8;

    mix-blend-mode: normal;
}
.titleBellowText{
    width: 275px;

    /* font-style: normal; */
    /* font-weight: 400; */
    font-size: 12px;
    line-height: 17px;
    /* or 142% */

    text-align: justify;

    color: #FFFFFF;

    mix-blend-mode: normal;
}
.firstColumn
{

}
.secondColumn{
  margin-left: 56px;
}
