

.aMainDiv {
    width: 100%;
    /*margin: auto;*/
    background-color: rgb(249, 249, 249);
    /*text-align: center;*/

    margin-bottom: 30px;
}

.aMainBodyTop {
    /*width: 100%;*/
    /*max-width: 1200px;*/
    margin: auto;
    /*margin-top: 54px;*/
    /*padding-top: 54px;*/
    display: flex;

    max-width: 1200px;
}

.aMainBody {
    /*width: 100%;*/
    /*max-width: 1200px;*/
    margin: auto;
    /*margin-top: 54px;*/
    /*padding-top: 54px;*/
    display: flex;

    max-width: 1200px;
}

.firstRow {
    display: flex;
    max-width: 1200px;
}

.mainText {
    margin: 165px 108px 35px 105px;
}

.aboutImage {
    margin-top: 155px;
    margin-left: 10px;
    width: 260px;
    height: 312px;
}

.aTopText {
    margin-bottom: 30px;
    font-weight: 300;

    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    letter-spacing: 3px;

    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
}

.title {
    margin-bottom: 106px;

    /* font-style: normal; */
    /* font-weight: 700; */
    font-size: 60px;
    /*line-height: 76px;*/
    /* or 127% */

    letter-spacing: -2px;

    color: #00D1FF;
}

.text {
    width: 467px;

    padding-bottom: 45px;


    /* font-style: normal; */
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */

    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
}

.aTitle {
    margin-bottom: 40px;

    /* font-style: normal; */
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */


    color: #1E0E62;
}

.aFirstText {
    margin-bottom: 160px;

    /* font-style: normal; */
    /* font-weight: 400; */
    font-size: 18px;
    line-height: 27px;
    /* or 150% */


    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
}

.aSecondText {
    margin-bottom: 160px;

    /* font-style: normal; */
    /* font-weight: 400; */
    font-size: 18px;
    line-height: 27px;
    /* or 150% */


    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
}

.aFirstColumn {
    margin-top: 54px;
    margin-left: 108px;
    width: 450px;
}

.aSecondColumn {
    margin-top: 54px;
    margin-left: 84px;
    width: 450px;
}

.aboutImageMobile {
    display: none;
}

@media (max-width: 1200px) {
    .aboutImage {
        display: none;
    }

    .aboutImageMobile {
        display: block;
        margin: 110px auto 60px auto;
        width: 150px;
        height: 180px;
    }

    .aMainBody {
        display: inline-block;
        width: 90vw;
        margin-left: 5vw;
        margin-right: 5vw;
        text-align: justify;

    }

    .aMainBodyTop {
        display: inline-block;
        margin-left: 10vw;
        margin-right: 10vw;
        text-align: center;
    }

    .text {
        padding-bottom: 55px;
        width: 80vw;
        margin: auto;
        line-height: 24px;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;

        text-align: center;

        color: rgba(0, 0, 0, 0.6);

        mix-blend-mode: normal;
    }

    .mainText {
        margin: 57px auto 0 auto;
    }

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;

        text-align: center;
        letter-spacing: -1px;

        color: #00D1FF;

        margin-bottom: 45px;
    }

    .aTopText {
        margin: auto auto 28px auto;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 3px;
    }

    .aFirstColumn {
        margin-top: 42px;
        margin-left: 0;
        width: 90vw;
    }

    .aSecondColumn {
        margin-top: 75px;
        margin-left: 0;
        width: 90vw;
    }

    .aFirstText {
        margin-bottom: 75px;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        text-align: justify;

        color: rgba(0, 0, 0, 0.6);

        mix-blend-mode: normal;
    }

    .aSecondText {
        margin-bottom: 83px;

        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        text-align: justify;

        color: rgba(0, 0, 0, 0.6);

        mix-blend-mode: normal;
    }

    .aTitle {
        margin-bottom: 30px;
        text-align: center;
    }
}
