/* @font-face {
    font-family: 'ITC Avant Garde Gothic Std';
    src: url('../../styles/itc.otf') format('opentype');
    font-display: auto;
} */
.wideDiv{
    display: table;
    width: 100%;
    color:white;
}

.limitedDiv{
    max-width: 1200px;
    width: 1200px;
    margin: auto;
}

.titleContainer{
    width: 100%;
    padding-top: 61px;
    padding-bottom: 70px;
    text-align: center;
    letter-spacing: -0.4px;
}

.title{

    font-size: 40px;
    margin: 60px auto auto;
    text-align: center;
    max-width: 515px;
    font-weight: 700;
    letter-spacing: -0.4;
}

.secondTitle{
    font-size: 24px;
    margin: 39px auto auto;
    text-align: center;
    max-width: 625px;
    color: rgba(21, 20, 57, 0.4);
}

.trust{
    margin-top: 80px;
    display: flex;
    width: 100%;
}

.contents{
    display: flex;
    padding-bottom: 111px;
    padding-left: 86px;
    margin :0;
}

.content{
    display: flow-root;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 27px;
    /* font-weight: 400; */
}

.rightContent{
    width: 481px;
}

.leftContent{
    width: 484px;
    margin-right: 66px;
}

.trustTitle{


    display: flex;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 31px;
    text-align: left;
    color: #FFFFFF;
    font-weight: 700;
}

.trustText{


    display: flex;
    font-size: 18px;
    line-height: 27px;
    margin: auto;
    text-align: justify;
    letter-spacing: -0.2px;
    font-weight: 400;
}
@media (max-width: 1200px) {
    .limitedDiv {
        width: 100%;
    }

    .title{


        font-size: 30px;
        font-weight: 700;
        margin: auto auto;
        text-align: center;
        line-height: 40px;
        letter-spacing: -0.4px;
    }

    .titleContainer{
        width: calc(100% - 11%);
        padding-top: 61px;
        padding-bottom: 70px;
        margin: auto auto;
        letter-spacing: -0.4px;
    }

    .contents{
        display: block;
        margin-left: 7.5%;
        margin-right: 7.5%;
        padding-left:0;
    }

    .content{
        display: flow-root;
        color: #FFFFFF;
        font-size: 18px;
        line-height: 27px;
        /* font-weight: 400; */
    }

    .rightContent{
        margin: auto;
        /*width: calc(100% - 48px);*/
        width: 100%;
        max-width: 639px;
    }

    .leftContent{
        /*width: calc(100% - 48px);*/
        margin: auto auto 50px auto;
        width: 100%;
        max-width: 639px;

    }


}

/* @media (max-width: 360px) {
    .title {
        font-size: 10vw;
    }
    .content{

    }
    .trustTitle{
        font-size: 10vw;
    }
} */

@media (max-width: 230px) {
    .title {
        font-size: 13vw;
    }
    .content{

    }
    .trustTitle{
        font-size: 10.5vw;
    }
}