
@import './styles/fonts.css';

.grecaptcha-badge {
  visibility: hidden;
}

body {
  margin: 0;
  font-family: "HelveticaNeueCyr", Arial, "Lucida Grande", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
