
.storiesDiv {
    width: 1200px;
}

.titleSubTitleDiv {
    max-width: 4000px;
    /*background-image: url("./bg.svg");*/
    /*background-position: bottom;*/
    /*background-size: cover;*/
    /*background-repeat: no-repeat;*/
    padding-bottom: 150px;
    margin: auto;
}

.limitedDiv{
    max-width: 1200px;
    margin: auto;
}

.titleDiv {

    font-size: 48px;
    line-height: 60px;
    font-weight: 700;
    margin: auto auto;
    text-align: center;
    max-width: 715px;
    color: #1E0E62;
    padding-top: 178px;
    letter-spacing: -1.5px;
}

.secondTitleDiv {

    font-size: 20px;
    margin: 63px auto auto;
    text-align: center;
    line-height: 30px;
    max-width: 800px;
    width: 800px;
    color: rgba(0, 0, 0, 0.75);
    letter-spacing: -.5px;
}

.secondDiv {
    text-align: center;
    display: flex;
    margin: auto auto;
}

.secondDivMobile {
    text-align: center;
    margin: auto auto;
    display: none;
}

.contentDiv {
    width: 40%;
}

.desc1 {

    width: 250px;
    margin: auto;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.6);
}

.desc2 {


    width: 250px;
    margin: auto;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.6);
}

.desc3 {


    width: 250px;
    margin: auto;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.6);
}

.contributorImg {
    margin-bottom: 29px;
}

.commercialUserImg {
    margin-bottom: 29px;
}
.MaintainerImg{
    margin-bottom: 29px;
}

/*.sendButton{*/
/*    width: 136px;*/
/*    height: 60px;*/
/*    background-color: #7AB61E !important;*/
/*    border-radius: 100px !important;*/
/*}*/

/*.textBox{*/
/*    border-radius: 100px !important;*/
/*    width: 329px;*/
/*    margin-right: 16px !important;*/
/*}*/

/*@media (max-width: 4000px) {*/
/*    .titleSubTitleDiv {*/
/*        background: url("./bg.svg") no-repeat calc(-1 * (1780px - 100vw)/2);*/
/*    }*/
/*}*/
@media (max-width: 1200px) {
    .titleSubTitleDiv {
        background: none;
        padding-bottom: 75px;
    }

    .storiesDiv {
        width: 100%;
    }

    .secondTitleDiv {
        width: calc(100% - 12%);
        max-width: 650px;
        font-size: 16px;
        padding: 0 6% 0 6%;
        text-align: center;
        line-height: 25px;
        /* letter-spacing: -.5; */

    }

    .desc1 {
        width: 100%;
    }

    .desc2 {
        width: 100%;
    }

    .desc3 {
        width: 100%;
    }

    .contentDiv {
        width: 100%;
        margin: 0 11% 0 11%;
    }

    .titleDiv {
        max-width: 400px;
        width: 89%;
        font-size: 30px;
        line-height: 38px;
        padding: 108px 5.5% 0 5.5%;
    }

    .secondDiv {
        display: none;
    }

    .secondDivMobile {
        display: inline;
    }

    .sliderText {

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        /* or 138% */

        text-align: center;
        max-width: 270px;
        margin: 27px auto 0 auto;

        color: rgba(0, 0, 0, 0.6);
    }

    .sliderMain {
        width: 78.14%;
        margin-left: 10.93vw;
        margin-right: 10.93vw;
        margin-bottom: 50px;
    }

    .sliderContent {
        margin-top: 52px;
        width: 100%;
        height: 250px;
    }

    .sliderImage {
        width: 120px;
        height: 124px;
        text-align: center;
    }

    .sliderFooter {
        display: flex;
    }

    .selectedSlideItem {
        background-color: #5A7325;
        border-radius: 5px;
        width: 20%;
        height: 5px;
    }

    .unSelectedSlideItem {
        border-radius: 5px;
        background-color: rgba(196, 196, 196, 0.2);
        border-color: rgba(196, 196, 196, 0.2);
        width: 20%;
        height: 5px;
    }

    .customDotListStyle {
        /*background-color: #7ab61e;*/
        /*color: #7ab61e;*/
    }

    .customItemDotListStyle {
        width: 20%;
        color: #7ab61e;
        /*background-color: #7ab61e;*/
    }

    .active {
        width: 23%;
        max-width: 50px;
        border: none;
        height: 5px;
        background-color: #5A7325;
        margin-left: 5px;
        margin-right: 5px;
    }

    .inactive {
        width: 23%;
        max-width: 50px;
        height: 5px;
        border: none;

        background-color: #c4c4c4;
        opacity: 50%;
        margin-left: 5px;
        margin-right: 5px;
        /*border-radius: 10px;*/

    }
}
