/* @font-face {
    font-family: 'ITC Avant Garde Gothic Std';
    src: url('../../styles/itc.otf') format('opentype');
    font-display: auto;
} */

.storiesDiv{
    width: 1200px;
}

.title{

    font-size: 40px;
    font-weight: 500;
    margin: 150px auto 100px auto;
    line-height: 52px;
    text-align: center;
    max-width: 899px;
    width: 899px;
    color: #1E0E62;
    letter-spacing: -0.4px;
}

.secondTitle{
    font-size: 24px;
    margin: 39px auto auto;
    text-align: center;
    max-width: 625px;
    color: rgba(21, 20, 57, 0.4);
}

.problem{
    margin-top: 110px;
    display: flex;
    width: 100%;
}

.problems{

    font-size: 24px;
    margin: auto;
    text-align: center;
    width: 100%;
}

.content{
    display: flow-root;
    max-width: 645px;
    width: 61%;
    padding-top: 30px;
}

.problemsTitle{

    display: flex;
    font-size: 32px;
    font-weight: 700;
    margin-left: 20px;
    margin-bottom: 25px;
    color: rgba(30, 14, 98, 0.87);

    line-height: 52px;
    text-align: center;

    width: 100%;
    color: #1E0E62;
    letter-spacing: -0.7px;
}

.problemsText{

    display: flex;
    font-size: 18px;
    line-height: 27px;
    margin-left: 20px;
    /*margin: auto;*/
    width: 500px;
    max-width: 500px;
    text-align: justify;
    color: rgba(0, 0, 0, 0.6);
}

.icon{
    width: 38%;
    margin-top: 50px;
}

@media (max-width: 1200px) {
    .storiesDiv{
        width: 100%;
        margin-bottom: 76px;
    }

    .title{

        width: calc(100% - 11%);
        max-width: 600px;
        font-size: 25px;
        /* font-weight: 500; */
        line-height: 35px;
        letter-spacing: -.7px;
        padding: 0 5.5% 0 5.5%;
    }
    .problemsText{

        width: 89%;
        max-width: 639px;
        font-size: 18px;
        /* font-weight: 400; */
        margin: auto;
        line-height: 27px;
    }
    .content{
        width: 100%;
        max-width: none;
    }
    .problem{
        display: block;
    }
    .icon{
        margin: auto;
        width: auto;
    }
    .problemsTitle1 {
        width: 350px;
    }
    .problemsTitle2 {
        width: 220px;
    }
    .problemsTitle3 {
        width: 220px;
    }
    .problemsTitle{

        text-align: center;
        margin: 40px auto 20px auto;
        font-size: 22px;
        /*line-height: 60px;*/
         font-weight: 700;
    }

}

@media (max-width: 350px) {
    .image{
        width: 80%;
    }

    .problemsTitle1 {
        width: 220px;
    }
}