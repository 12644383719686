

.aMainDiv {
    width: 100%;
}

.mainText {
    position: relative;
    width: 1200px;
    padding-bottom: 690px;
    margin: 70px auto auto auto;
}

.aboutImage {
    position: absolute;
    top: 90px;
    left: 593px;
    margin-bottom: 35px;
    width: 552px;
    height: 564px;
}

.aTopText {

    position: absolute;
    top: 95px;
    left: 87px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    letter-spacing: 3px;

    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
    margin-bottom: 21px;

}

.title {
    position: absolute;
    top:141px;
    left: 87px;
    width: 504px;
    height: 120px;

    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 76px;
    /* or 127% */

    letter-spacing: -2px;

    color: #C9429B;

    margin-bottom: 57px;

    max-width: 440px;
}


.subTextImg {
    position: absolute;
    top: 531px;
    left: 93px;
}

.subText {
    position: absolute;
    top: 535px;
    left: 163px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.6);
    max-width: 382px;

    height: 33px;

    font-style: normal;
    /* or 129% */

}

.text {
    position: absolute;
    top: 322px;
    left: 87px;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */

    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
    width: 467px;

    padding-bottom: 98px;
}


.aTitle {
    margin-bottom: 40px;

    /* font-style: normal; */
    /* font-weight: 700; */
    font-size: 24px;
    line-height: 32px;
    /* or 133% */


    color: #1E0E62;
}

.aFirstText {
    margin-bottom: 160px;

    /* font-style: normal; */
    /* font-weight: 400; */
    font-size: 18px;
    line-height: 27px;
    /* or 150% */


    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
}

.aSecondText {
    margin-bottom: 160px;

    /* font-style: normal; */
    /* font-weight: 400; */
    font-size: 18px;
    line-height: 27px;
    /* or 150% */


    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
}

.aFirstColumn {
    margin-top: 54px;
    margin-left: 108px;
    width: 450px;
}

.aSecondColumn {
    margin-top: 54px;
    margin-left: 84px;
    width: 450px;
}

.chartTexts {
    width: 90%;
    margin: auto;
    max-width: 987px;
}

.chartText {
    font-weight: 400;
    font-size: 18px;
    text-align: justify;
    line-height: 27px;

    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 50px;
}

.chartSubText {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    text-align: justify;
    margin-bottom: 112px;
    line-height: 20px;
}

.aboutImageMobile {
    display: none;
}

@media (max-width: 1200px) {
    .subText {
        display: none;
    }

    .subTextImg {
        display: none;
    }

    .aboutImage {
        display: none;
    }

    .aboutImageMobile {
        position: static;
        display: flex;
        margin: auto auto 40px;
        max-width: 400px;
        width: 72%;
        min-height: 295px;
    }

    .mainText {
        position: static;
        width: 92vw;
        margin-left: 4vw;
        margin-right: 4vw;
        padding-bottom: 55px;
        text-align: justify;
    }

    .aMainBodyTop {
        display: block;
    }

    .text {
        position: static;
        display: flex;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        text-align: center;
        letter-spacing: -0.5px;

        color: rgba(0, 0, 0, 0.87);

        width: 90%;
        mix-blend-mode: normal;

        padding: 0;
        margin: 0 auto;
        max-width: 640px;
        /*width: 97%;*/
    }

    .title {
        display: flex;
        position: static;
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        /* or 127% */

        text-align: center;
        letter-spacing: -0.4px;

        color: #C9429B;

        margin: 22px auto 40px auto;
        max-width: none;
        width: 260px;
        height: auto;
    }

    .aTopText {
        position: static;
        font-weight: 300;
        font-size: 10px;
        line-height: 20px;

        text-align: center;
        letter-spacing: 3px;

        color: rgba(0, 0, 0, 0.6);

        padding-top: 23px;
        margin: auto auto;
        width: 290px;
    }

    .aFirstColumn {
        margin-top: 42px;
        margin-left: 0;
        width: 90vw;
    }

    .aSecondColumn {
        margin-top: 75px;
        margin-left: 0;
        width: 90vw;
    }

    .aFirstText {
        margin-bottom: 75px;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        text-align: justify;

        color: rgba(0, 0, 0, 0.6);

        mix-blend-mode: normal;
    }

    .aSecondText {
        margin-bottom: 83px;

        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        text-align: justify;

        color: rgba(0, 0, 0, 0.6);

        mix-blend-mode: normal;
    }

    .aTitle {
        margin-bottom: 37px;

    }

    .chartTexts {
        width: 90%;
    }
    .chartText {
        width: 100%;
        font-size: 16px;
        line-height: 26px;
        margin: 100px auto auto;
        max-width: 640px;


    }
    .chartSubText {
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        margin: 100px auto 121px;
        max-width: 640px;


    }
}
