.mainPanel {
    display: flex;
    width:970px;
}

.titlePanel {
    margin-right: 89px;
}

.titleFirstRow {
    display: flex;
}

.titleImage {

    width: 93px;
    height: 112px;
    margin-right: 27px;
    margin-bottom: 21px;
    margin-top: 100px;
}

.ideaBoosterImage {
    width: 20px;
    height: 25px;
    margin-right: 16px;
    margin-top: 81px;
}

.firstRowTopText {
    margin-top: 81px;
}

.secondRowTopText {
    margin-top: 44px;
}

.firstColumnBellowText {
    margin-left: 36px;

    width: 239px;

    /* font-style: normal; */
    font-weight: 100;
    font-size: 12px;
    line-height: 17px;
    /* or 142% */


    color: #FFFFFF;

    mix-blend-mode: normal;
}

.decentralizedPanel {
    position: absolute;
    top: 295px;
    margin-left: 36px;
    width: 362px;

    /* font-style: normal; */
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    /* or 150% */


    color: #95C11F;

    mix-blend-mode: normal;
}

.menuItemTopText {
    cursor: pointer;
    margin-bottom: 13px;


    /* font-style: normal; */
    /* font-weight: 700; */
    font-size: 18px;
    line-height: 27px;
    /* or 150% */

    text-align: justify;

    color: #E8E8E8;

    mix-blend-mode: normal;
}

.menuItemTopText:hover {
    color: #F8FEB9;;
}

.titleTopText {
    margin-top: 158px;

    /* font-style: normal; */
    /* font-weight: 700; */
    font-size: 24px;
    line-height: 27px;
    /* or 150% */

    text-align: justify;

    color: #E8E8E8;

    mix-blend-mode: normal;
}

.titleBellowText {
    width: 275px;

    /* font-style: normal; */
    /* font-weight: 400; */
    font-size: 12px;
    line-height: 17px;
    /* or 142% */

    text-align: justify;

    color: #FFFFFF;

    mix-blend-mode: normal;
}

.firstColumn {

}

.secondColumn {
    margin-left: 56px;
}
