

.storiesDiv{
    width: 1200px;
}

.title{

    font-size: 40px;
    line-height: 52px;
     font-weight: 500;
    margin: 140px auto auto;
    text-align: center;
    max-width: 899px;
    color: #1E0E62;
    letter-spacing: -0.4px;
}

.secondTitle{
    font-size: 24px;
    margin: 39px auto auto;
    text-align: center;
    max-width: 625px;
    color: rgba(21, 20, 57, 0.4);
}

.story{
    margin-top: 140px;
    display: flex;
    width: 100%;
}

.stories{
    font-size: 24px;
    margin: 160px 0 120px 87px;
    text-align: center;
    width: 1035px;
}

.leftContent{
    display: flow-root;
    margin: 0 69px 0 0;
    width: 453px;
    color: rgba(0, 0, 0, 0.6);
}

.rightContent1{
    display: flow-root;
    margin: 80px 0 0 113px;
    width: 453px;
    color: rgba(0, 0, 0, 0.6);
}

.rightContent2{
    display: flow-root;
    margin: 0 0 0 100px;
    width: 453px;
    color: rgba(0, 0, 0, 0.6);
}

.storiesTitle{

    display: flex;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 60px;
    text-align: left;
    max-width: 310px;
    color: #1E0E62;
    letter-spacing: -0.4px;
}

.fistStoriesTitle{

    display: flex;
    font-size: 40px;
     font-weight: 700;
    line-height: 50px;
    margin-bottom: 60px;
    text-align: left;
    max-width: 270px;
    color: #1E0E62;
    letter-spacing: -0.4px;
}

/* .fistStoriesTitle{
    display: flex;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 60px;
    text-align: left;
    max-width: 270px;
    color: #1E0E62;
} */

.learnMoreBtn{
    /*box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);*/
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid rgb(220, 222, 224);
    cursor: pointer;
    margin-top: 40px;
    float: left;
    color: rgba(0, 0, 0, 0.38);
    background-color: #FFFFFF;
    /*padding: 10px 28px;*/
    text-decoration: none;
    min-width: 150px;
    min-height: 50px;
}

.learnMoreBtn:hover {
    background-color: rgb(247, 248, 250);
    border-color: rgb(247, 248, 250);
}

.learnMoreBtn:active {
    /*background-color: #3e8e41;*/
    box-shadow: 0 1px #e6e6e6;
    transform: translateY(1px);
}

.storiesText{

    display: flex;
    font-size: 18px;
    line-height: 27px;
    margin: auto;
    text-align: justify;
}

.icon1{
    margin-top: 60px;
    width: 463px;
}
.icon2{
    width: 431px;
}
.icon3{
    width: 513px;
}
.icon4{
    width: 447px;
    margin-top: 20px;
}
@media (max-width: 1200px) {
    .storiesDiv{
        width: 88.75%;
        margin-right: 5.625%;
        margin-left: 5.625%;
    }
    .title{

        /* font-style: normal; */
        /* font-weight: 550; */
        font-size: 25px;
        line-height: 35px;
        /* or 140% */

        text-align: center;
        letter-spacing: -0.7px;

        color: #1E0E62;
    }
}
