.mainPanel {
    display: flex;;
    width:970px;
}

.titlePanel {
    margin-right: 89px;
}

.titleFirstRow {
    display: flex;
}

.titleImage {

    width: 93px;
    height: 112px;
    margin-right: 27px;
    margin-bottom: 21px;
    margin-top: 100px;
}

.firstContactsRow{
    display: flex;
    margin-top: 50px;
}

.contactsRow{
    display: flex;
    margin-top: 15px;
}

.contactsLink{
    display: flex;
    text-decoration: none;
}

.ideaBoosterImage {
    width: 20px;
    height: 25px;
    margin-right: 16px;
}

.contactEmail {
    width: 30px;
    height: 30px;
    margin-right: 16px;
    background-size: cover;
    background: url("./email.svg") no-repeat;
}

.contactEmail:hover {

    background: url("./email-hover.svg") no-repeat;
}

.contactTelegram {
    width: 30px;
    height: 30px;
    margin-right: 16px;
    background-size: cover;
    background: url("./telegram2.svg") no-repeat;
}

.contactTelegram:hover {

    background: url("./telegram-hover2.svg") no-repeat;
}

.contactText{
    font-size: 12px;

    color: #FFFFFF;
    padding-top:10px;
}

.firstRowTopText {
    margin-top: 102px;
}

.firstRowSubText {
    font-size: 12px;

    /* font-style: normal; */
    /* font-weight: 400; */
}

.secondRowTopText {
    margin-top: 44px;
}

.firstColumnBellowText {
    margin-left: 36px;

    width: 239px;

    /* font-style: normal; */
    /* font-weight: 400; */
    font-size: 12px;
    line-height: 17px;
    /* or 142% */


    color: #FFFFFF;

    mix-blend-mode: normal;
}

.decentralizedPanel {
    position: absolute;
    top: 295px;
    margin-left: 36px;
    width: 362px;

    /* font-style: normal; */
    /* font-weight: 400; */
    font-size: 10px;
    line-height: 15px;
    /* or 150% */


    color: #95C11F;

    mix-blend-mode: normal;
}

.menuItemTopText {
    cursor: pointer;
    margin-bottom: 13px;


    /* font-style: normal; */
    /* font-weight: 700; */
    font-size: 18px;
    line-height: 27px;
    /* or 150% */

    text-align: justify;

    color: #E8E8E8;

    mix-blend-mode: normal;
}

.menuItemTopText:hover {
    color: #F8FEB9;;
}

.titleTopText {
    margin-top: 158px;

    /* font-style: normal; */
    /* font-weight: 700; */
    font-size: 24px;
    line-height: 27px;
    /* or 150% */

    text-align: justify;

    color: #E8E8E8;

    mix-blend-mode: normal;
}

.titleBellowText {
    width: 275px;

    /* font-style: normal; */
    /* font-weight: 400; */
    font-size: 12px;
    line-height: 17px;
    /* or 142% */

    text-align: justify;

    color: #FFFFFF;

    mix-blend-mode: normal;
}

.firstColumn {

}

.secondColumn {
    margin-left: 56px;
}

.secondColumnFirstRow{
    font-size: 12px;

    color: rgba(255, 255, 255,1);
    margin-top: 225px;
}

.contactInformation {
    display: flex;
    width: 300px;
    margin: 10px auto auto;
}

.linkedIn {
    background: url("./linkedin.svg") no-repeat;
    background-size: cover;
}

.linkedIn:hover {
    background: url("./linkedin-hover.svg") no-repeat;
    background-size: cover;
}

.twitter {
    background: url("./twitter.svg") no-repeat;
    background-size: cover;
}

.twitter:hover {
    background: url("./twitter-hover.svg") no-repeat;
    background-size: cover;
}

.telegram {
    background: url("./telegram.svg") no-repeat;
    background-size: cover;
}

.telegram:hover {
    background: url("./telegram-hover.svg") no-repeat;
    background-size: cover;
}

.facebook {
    background: url("./facebook.svg") no-repeat;
    background-size: cover;
}

.facebook:hover {
    background: url("./facebook-hover.svg") no-repeat;
    background-size: cover;
}

.medium {
    background: url("./medium.svg") no-repeat;
    background-size: cover;
}

.medium:hover {
    background: url("./medium-hover.svg") no-repeat;
    background-size: cover;
}
.contactLogo {
    width: 25px;
    height: 25px;
    vertical-align: bottom;
    margin-right: 12px;
}
