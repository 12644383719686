

.cMainDiv {
    width: 100%;
    /*margin: auto;*/
    background-color: rgb(249, 249, 249);
    /*text-align: center;*/

    margin-bottom: 30px;
}

.cMainBody {
    /*width: 100%;*/
    /*max-width: 1200px;*/
    /*margin: auto;*/
    display: flex;

    max-width: 1200px;
    margin: auto;
}

.firstRow {
    display: flex;
    max-width: 1200px;
}

.mainText {
    margin: 165px 98px 45px 105px;
}

.contactImage {
    margin: 70px 205px 25px 0;
    padding-top: 105px;
    width: 260px;
    height: 312px;
}

.topText {
    margin-bottom: 30px;


    /* font-style: normal; */
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    letter-spacing: 3px;

    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
}

.title {
    margin-bottom: 108px;

    /* font-style: normal; */
    /* font-weight: 700; */
    font-size: 60px;
    /* or 127% */

    letter-spacing: -2px;

    color: #00D1FF;
}

.text {
    width: 467px;

    padding-bottom: 45px;
    max-width: 640px;

    /* font-style: normal; */
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */


    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
}

.email {
    margin: 38px 70px 144px 105px;
    width: 473px;
    height: 275px;
    left: 105px;
    top: 606px;

    background: rgba(196, 196, 196, 0.2);
    border-radius: 30px;
    cursor: pointer;
}

.telegram {
    margin-top: 38px;
    width: 473px;
    height: 275px;
    left: 105px;
    top: 606px;

    background: rgba(196, 196, 196, 0.2);
    border-radius: 30px;
    cursor: pointer;
}

.linkImage {
    margin-top: 36px;
    margin-bottom: 24px;
    text-align: center;
}

.linkText {

    /* font-style: normal; */
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    text-align: center;

    /* Colors / Heading */

    color: #1E0E62;
}

.link {
    width: 100%;
    margin-top: 20px;
    text-align: center;

    /* font-style: normal; */
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    /* or 169% */

    align-items: center;
    color: #9D9D9C;
}
.cTopText {
    margin-bottom: 28px;


    /* font-style: normal; */
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    letter-spacing: 3px;

    color: rgba(0, 0, 0, 0.6);

    mix-blend-mode: normal;
}

.contactImageMobile {
    display: none;
}

@media (max-width: 1200px) {

    .contactImage {
        display: none;
    }

    .contactImageMobile {
        display: block;
        margin: 122px auto 78px auto;
        width: 150px;
        height: 151px;
    }

    .mainText{
        margin: 57px 10vw 0 10vw;
    }
    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;

        text-align: center;
        letter-spacing: -1px;

        color: #00D1FF;

        margin-bottom: 45px;
    }



    .text {
        padding-bottom: 55px;
        width: 80vw;
        margin: auto;
        line-height: 24px;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;

        text-align: center;

        color: rgba(0, 0, 0, 0.6);

        mix-blend-mode: normal;

    }
    .email {
        margin-bottom: 28px;
        margin-left: 10vw;
        margin-right: 10vw;
    }
    .telegram{
        margin-bottom: 128px;
        margin-left: 10vw;
        margin-right: 10vw;
    }
    .cTopText {
        margin: auto auto 28px auto;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 3px;
    }
    .linkText {
        font-size: 18px;
    }
    .cMainBody {
        flex-flow: wrap;
        align-items: center;
        justify-content: center;
    }
    .topText{
        padding-top: 95px;
    }
}