.header{
    width: 100%;
    height: 70px;
    background-color: white;
    position: fixed;
    padding: 0;
    top: 0;
    z-index: 100;
}
.headerLink{
    /*margin: 30px;*/
    text-decoration: none;
    font-size: 16px;
}
.headerLink:hover{
    text-decoration: underline;
}

.flexDiv{
    display: flex;
    max-width: 1200px;
    margin: auto;
    height: 70px;
    position: fixed;
    top: 0;
    left: calc(50% - 600px);
    width: 1200px;
    background: white;
    box-shadow: 0 1px #EBEAED;
}

.shadowed{
    box-shadow: 0 1px #EBEAED;
}

.linksDiv{
    display: flex;
    width: 70%;
}

.logoDiv{
    width: 30%;
    margin: 10px 86px 0 0;
}

.logo{
    height: 48px;
    padding-left: 6px;
    padding-top: 3px;
}
.logo:hover{
    cursor: pointer;
    opacity: 0.9;
    /*height: 47px;*/
}

.logo:active{
    opacity: 1.0;
}

.button{
    text-decoration: none !important;
    /* font-style: normal!important; */
    font-weight: 400;
    /*line-height: 27px!important;*/
    margin: 0 30px 0 30px !important;
}

.button:hover{
    border-bottom: solid #75973D;
    padding-top: 3px !important;
}

.hButton{
    display: none;
}

@media only screen and (max-width: 1200px){

    .hButton {
        position: absolute;
        display: block;
        width: 18px;
        height: 19px;
        right: 19px;
        top: 18px;
    }
    .flexDiv{
        width: 100%;
        left: 0;
        height: 54px;
    }
    .linksDiv{
        display: none;
    }

    .logoDiv{
        margin: 6px 0 0 0;
        width: 140px;
    }

    .logo{
        width: 140px;
        height: 38px;
    }
    .header{
        height:54px;
    }
}
